@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/antd/style';
@import 'assets/styles/bootstrap/style';
@import 'assets/styles/airui/style';
@import 'assets/styles/nprogress/style';
@import 'assets/styles/chartist/style';
@import 'assets/styles/chartist-tooltip-plugin/style';
@import 'assets/styles/jvectormap/style';
@import 'assets/styles/react-sortable-tree/style';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import 'assets/styles/react-draft-wysiwyg/style';

.googleRecaptcha {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

input.autSuccess {
  border: 1px solid #46be8a;
}

input.autFailed {
  border: 1px solid #f00;
}

.autSuccess {
  input {
    border: 1px solid #46be8a;
  }
  input:focus {
    border: 1px solid #46be8a !important;
  }
  input:hover {
    border: 1px solid #46be8a;
  }

  .ant-select-selection {
    border: 1px solid #46be8a;
    &:focus {
      border: 1px solid #46be8a !important;
    }
    &:hover {
      border: 1px solid #46be8a;
    }
  }
}

.autFailed {
  input {
    border: 1px solid #f00;
  }
  input:focus {
    border: 1px solid #f00 !important;
  }
  input:hover {
    border: 1px solid #f00;
  }

  .ant-select-selection {
    border: 1px solid #f00;
    &:focus {
      border: 1px solid #f00 !important;
    }
    &:hover {
      border: 1px solid #f00;
    }
  }
}

.ant-input::placeholder {
  color: #bfbfbf !important;
}

button:focus {
  outline: 0;
}

body .ant-tabs-nav .ant-tabs-tab {
  margin: 0 3px !important;
}

.tableCardTabs {
  .ant-tabs-nav-container {
    height: 32px !important;
  }

  .ant-tabs-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .ant-tabs-tab {
    font-size: 14px;
    padding: 8px 10px !important;
    height: auto !important;
    line-height: 1 !important;
    margin: 0 !important;
    border: 1px solid #e8e8e8 !important;
    color: #786fa4;
  }

  .ant-tabs-tab-active {
    border-bottom: none !important;
  }
}

.ant-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rowMinute {
  position: relative;
}

tr.ant-table-row {
  height: 80px;
}

.tablePrelive {
  margin-top: 20px;
  .ant-table-wrapper {
    .ant-table table {
      overflow-x: auto;
      white-space: nowrap;
      @media (max-width: 1950px) {
        display: block;
      }
    }
  }
}

.ant-calendar-picker-input.ant-input {
  line-height: 1 !important;
}

a.oddBack {
  color: #3030ff;
  cursor: pointer;
}
a.oddLay {
  color: #ff4143;
  cursor: pointer;
}

.selectTeamsPastGames {
  width: 100%;
  max-width: 300px;
  margin-right: 10px;

  .ant-select-selection__placeholder {
    color: #afa9ce !important;
  }
}

.inputSelectReact {
  width: 100%;

  .react-select__control {
    height: 32px;
    min-height: auto;
    border: 1px solid #e4e9f0;
  }
  .react-select__placeholder,
  .react-select__single-value {
    top: calc(50% - 1.5px);
  }
  .react-select__placeholder {
    color: #bfbfbf;
  }
  .react-select__indicator-separator {
    margin-top: 3px;
  }
  .react-select__indicator {
    margin-top: -2px;
    padding: 0 8px;
  }
}

.recordSelect {
  background: #e8edff;
}

.linkTable {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    color: #786fa4;
  }
}

.errorLogs {
  color: #ff4f4f !important;
}

.dashGamesContent {
  width: 100%;
  padding: 10px 20px;
}

.c-dashContentGames {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
}

.c-dash-actionBts {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 20px;
  position: relative;

  .c-dash-btBack {
    position: absolute;
    left: 0;
    border: 1px solid #1b55e3;
    border-radius: 5px;
    padding: 3px 10px;
    color: #1b55e3;
  }

  .orderByMinute {
    cursor: pointer;
    color: black;
    span {
      color: $blue;
    }
  }

  button {
    border: 1px solid #1b55e3;
    color: #1b55e3;
    border-radius: 5px;
    margin-left: 15px;
    margin-bottom: 5px;
  }

  .btActive {
    background: #1b55e3;
    color: #fff;
  }

  .cardfilters {
    width: 100%;
    max-width: 200px;
    position: absolute;
    right: 0;

    div {
      margin-top: 0 !important;
    }

    p {
      display: none;
    }

    .ant-select {
      margin: 0 !important;
    }
  }
}

.c-dash-actionBts2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .filter {
    width: 100%;
    max-width: 400px;
  }
}

.logoHeader {
  width: 100%;
  max-width: 200px;
  position: absolute;
  top: 13px;
  left: 20px;

  img {
    display: block;
    width: 100%;
  }
}

.c-CardDash {
  width: 100%;
  border: 1px solid $gray-2;
  border-radius: 5px;
  padding: 10px;
  position: relative;

  .graphsContainer {
    display: flex;
  }

  .graph {
    max-width: 195px;
    margin-top: 5px;
  }

  .odds {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 0;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 80px;

      .title {
        font-size: 14px;
        font-weight: 400;
      }

      .initial,
      .current {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        height: 24px;
        color: black;
        border: 2px solid #3f00ff;
        width: 100%;
        max-width: 50px;
        border-radius: 5px;
        margin-top: 5px;
      }

      .current {
        border-color: #56ff00;
      }
    }
  }

  .header {
    .title {
      font-size: 16px;
      display: block;
      font-weight: 700;
      color: $black;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 230px;
      margin: 0 auto;
    }

    .buttons {
      display: flex;
      margin-top: 15px;
      justify-content: center;

      .btIrp {
        font-size: 16px;
        background: $blue;
        color: $white;
        cursor: pointer;
        padding: 4px 0;
        text-align: center;
        border-radius: 5px;
        width: 100%;
        max-width: 90px;
        margin: 0 5px;
      }

      .btIrp2 {
        top: 42px;
      }
    }

    .userMethods {
      margin-top: 15px;
      display: flex;
      height: 15px;
      justify-content: center;

      .ball {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin: 0 3px;
        cursor: pointer;
      }
    }

    .infoGame {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      .placar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        color: $black;
        text-align: center;
      }
      .placarHT {
        font-size: 18px;
        margin-top: 0;
        font-weight: 400;
        opacity: 0.5;
      }
      .acreHT,
      .acreFT {
        display: flex;
        margin-left: 8px;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 25px;
        height: 25px;
        font-size: 16px;
        font-weight: 700;
        color: $black;
        background: $yellow;
      }
      .minutes {
        position: absolute;
        top: 1px;
        left: 1px;
        border-radius: 5px;
        width: 100%;
        max-width: 26px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: red;
        color: white;
      }
      .favorites {
        font-size: 24px;
        color: #ffca00;
        position: absolute;
        top: 3px;
        right: 5px;
        cursor: pointer;
      }
      .newOrder {
        position: absolute;
        width: 20px;
        height: 26px;
        right: 35px;
        top: 6px;
        cursor: pointer;
        font-size: 20px;
        color: green;
      }
      .infoSofa {
        cursor: pointer;
        position: absolute;
        right: 58px;
        top: 8px;
        i {
          font-size: 22px;
          color: rgb(49, 49, 232);
        }
      }
      .userOperations {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
        width: 100%;
        max-width: 130px;
        position: absolute;
        right: 6px;
        top: 40px;
        cursor: pointer;

        .operations {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        span.ball {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          width: 75px;
          height: 20px;
          font-size: 12px;
          border-radius: 5px;
        }

        span.back {
          color: blue;
        }

        span.lay {
          color: red;
        }
      }
    }
  }

  .separator {
    width: 90%;
    margin: 0 auto;
    height: 2px;
    background: $black;
    margin-top: 5px;
  }

  .content,
  .content2 {
    margin-top: 5px;

    .blockInfos {
      width: 90%;
      margin: 0 auto;
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .name {
      font-size: 14px;
      font-weight: 400;
      margin: 0 5px;
      color: $black;
    }

    .number {
      font-size: 16px;
      font-weight: 700;
      color: $black;
      width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .high {
      background: $greenH;
    }
  }

  .content2 {
    .name {
      color: $blue;
    }
  }
}

.containerArnold {
  display: flex;
  height: 500px;
  overflow-y: scroll;

  .w-50 {
    width: 50%;

    .header {
      font-size: 20px;
      text-align: center;
      font-weight: 700;
    }

    .infos {
      margin: 0 auto;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      width: 100%;
      max-width: 350px;
      border: 1px solid #e4e9f0;
      border-radius: 5px;
      padding: 0 20px;
      padding-bottom: 10px;

      .head {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 3px 0;
        border-radius: 0 0 8px 8px;
        text-align: center;
        width: 100%;
        max-width: 170px;
        margin: 0 auto;
        margin-bottom: 15px;
        background: #e4e9f0;
      }
    }

    span.perc {
      font-size: 12px;
    }
  }
}

.cPointer {
  cursor: pointer;
}
